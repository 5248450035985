define("reiserei-app/components/landing-page/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='landing-page'>
    <Layout::Wrapper>
      <Layout::VerticalStack @gap='xlarge' as |Item|>
        <Item>
          <Layout::Center>
            <img
              src='/assets/img/rumreiserei-logo.png'
              alt=''
              role='presentation'
              class='landing-page-logo'
            />
          </Layout::Center>
        </Item>
  
        <Item>
          <Layout::Center>
            {{svg-jar 'rumreiserei' class='landing-page-title'}}
          </Layout::Center>
        </Item>
  
        <Item>
          <LandingPage::Search />
        </Item>
      </Layout::VerticalStack>
    </Layout::Wrapper>
  </div>
  */
  {
    "id": "LY2Y0jDs",
    "block": "{\"symbols\":[\"Item\"],\"statements\":[[10,\"div\"],[14,0,\"landing-page\"],[12],[2,\"\\n  \"],[8,\"layout/wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/vertical-stack\",[],[[\"@gap\"],[\"xlarge\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/center\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"img\"],[14,\"src\",\"/assets/img/rumreiserei-logo.png\"],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,0,\"landing-page-logo\"],[12],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"layout/center\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[1,[30,[36,0],[\"rumreiserei\"],[[\"class\"],[\"landing-page-title\"]]]],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"landing-page/search\",[],[[],[]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[1]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "reiserei-app/components/landing-page/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});