define("reiserei-app/application/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "otviP7y5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,0],[\"rumreiserei\"],null]],[2,\"\\n\\n\"],[8,\"site-wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page-title\",\"-outlet\",\"component\"]}",
    "moduleName": "reiserei-app/application/template.hbs"
  });

  _exports.default = _default;
});