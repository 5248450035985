define("reiserei-app/enums/water-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.WaterDistance = void 0;
  var WaterDistance;
  _exports.WaterDistance = WaterDistance;

  (function (WaterDistance) {
    WaterDistance[WaterDistance["No"] = 0] = "No";
    WaterDistance[WaterDistance["Close"] = 1] = "Close";
    WaterDistance[WaterDistance["Direct"] = 2] = "Direct";
  })(WaterDistance || (_exports.WaterDistance = WaterDistance = {}));
});