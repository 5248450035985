define("reiserei-app/components/ui/icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{svg-jar @icon class='icon'}}
  */
  {
    "id": "ejvNNHpJ",
    "block": "{\"symbols\":[\"@icon\"],\"statements\":[[1,[30,[36,0],[[32,1]],[[\"class\"],[\"icon\"]]]]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "reiserei-app/components/ui/icon.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});