define("reiserei-app/components/map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='map' ...attributes>
    <LeafletMap @center={{@center}} @zoom={{or @zoom 7}} as |layers|>
      <layers.tile @url='http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png' />
  
      {{#each @points as |point|}}
        <layers.marker
          @lat={{point.lat}}
          @lng={{point.lng}}
        >
          Blub
        </layers.marker>
      {{/each}}
    </LeafletMap>
  </div>
  */
  {
    "id": "jQKq8Zbg",
    "block": "{\"symbols\":[\"layers\",\"point\",\"&attrs\",\"@center\",\"@zoom\",\"@points\"],\"statements\":[[11,\"div\"],[24,0,\"map\"],[17,3],[12],[2,\"\\n  \"],[8,\"leaflet-map\",[],[[\"@center\",\"@zoom\"],[[32,4],[30,[36,0],[[32,5],7],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1,[\"tile\"]],[],[[\"@url\"],[\"http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png\"]],null],[2,\"\\n\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,6]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1,[\"marker\"]],[],[[\"@lat\",\"@lng\"],[[32,2,[\"lat\"]],[32,2,[\"lng\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        Blub\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"or\",\"-track-array\",\"each\"]}",
    "moduleName": "reiserei-app/components/map.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});