define("reiserei-app/components/layout/sidebar-left/-sidebar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='sidebar-left__sidebar'>{{yield}}</div>
  */
  {
    "id": "x+FBl1jp",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"sidebar-left__sidebar\"],[12],[18,1,null],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/layout/sidebar-left/-sidebar.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});