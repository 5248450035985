define("reiserei-app/enums/hotel-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HotelTag = void 0;
  var HotelTag;
  _exports.HotelTag = HotelTag;

  (function (HotelTag) {
    HotelTag["Sustainable"] = "Sustainable";
    HotelTag["BikeRental"] = "BikeRental";
    HotelTag["BikeFriendly"] = "BikeFriendly";
    HotelTag["PoolIndoor"] = "PoolIndoor";
    HotelTag["PoolOutdoor"] = "PoolOutdoor";
    HotelTag["PoolOutdoorHeated"] = "PoolOutdoorHeated";
    HotelTag["Sauna"] = "Sauna";
    HotelTag["Massage"] = "Massage";
    HotelTag["ActivityProgram"] = "ActivityProgram";
    HotelTag["ChildProgram"] = "ChildProgram";
    HotelTag["ChildCare"] = "ChildCare";
    HotelTag["AdultsOnly"] = "AdultsOnly";
    HotelTag["FamilyHotel"] = "FamilyHotel";
    HotelTag["FamilyOwned"] = "FamilyOwned";
    HotelTag["BookOnline"] = "BookOnline";
  })(HotelTag || (_exports.HotelTag = HotelTag = {}));
});