define("reiserei-app/enums/mountain-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.MountainDistance = void 0;
  var MountainDistance;
  _exports.MountainDistance = MountainDistance;

  (function (MountainDistance) {
    MountainDistance[MountainDistance["No"] = 0] = "No";
    MountainDistance[MountainDistance["Close"] = 1] = "Close";
    MountainDistance[MountainDistance["Direct"] = 2] = "Direct";
  })(MountainDistance || (_exports.MountainDistance = MountainDistance = {}));
});