define("reiserei-app/components/hotel-search/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Section>
    <Layout::Wrapper>
     <HotelSearch::Items />
    </Layout::Wrapper>
  </Layout::Section>
  */
  {
    "id": "MdH4xXTe",
    "block": "{\"symbols\":[],\"statements\":[[8,\"layout/section\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,\"layout/wrapper\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n   \"],[8,\"hotel-search/items\",[],[[],[]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/hotel-search/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});