define("reiserei-app/components/hotel-list-item/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div
    class='hotel-list-item__image'
    style={{image-url-css-var @image.smallUrl}}
  ></div>
  */
  {
    "id": "tPzu+g/K",
    "block": "{\"symbols\":[\"@image\"],\"statements\":[[10,\"div\"],[14,0,\"hotel-list-item__image\"],[15,5,[30,[36,0],[[32,1,[\"smallUrl\"]]],null]],[12],[13]],\"hasEval\":false,\"upvars\":[\"image-url-css-var\"]}",
    "moduleName": "reiserei-app/components/hotel-list-item/image.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});