define("reiserei-app/enums/hotel-category", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.HotelCategory = void 0;
  var HotelCategory;
  _exports.HotelCategory = HotelCategory;

  (function (HotelCategory) {
    HotelCategory[HotelCategory["None"] = 0] = "None";
    HotelCategory[HotelCategory["Stars1"] = 1] = "Stars1";
    HotelCategory[HotelCategory["Stars2"] = 2] = "Stars2";
    HotelCategory[HotelCategory["Stars3"] = 3] = "Stars3";
    HotelCategory[HotelCategory["Stars4"] = 4] = "Stars4";
    HotelCategory[HotelCategory["Stars5"] = 5] = "Stars5";
  })(HotelCategory || (_exports.HotelCategory = HotelCategory = {}));
});