define("reiserei-app/helpers/layout-join-classes", ["exports", "ember-layout-components/helpers/layout-join-classes"], function (_exports, _layoutJoinClasses) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _layoutJoinClasses.default;
    }
  });
});