define("reiserei-app/components/hotel-list-item/details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Cluster
    @verticalAlign='bottom'
    @noWrap={{true}}
    @fullWidthOnMobile={{true}} as |Item|
  >
    <Item>
      <HotelListItem::Tags @hotel={{@hotel}} />
    </Item>
  
    <Item @float='right'>
      <HotelListItem::GoogleRating @hotel={{@hotel}} />
    </Item>
  </Layout::Cluster>
  */
  {
    "id": "0fji8gkD",
    "block": "{\"symbols\":[\"Item\",\"@hotel\"],\"statements\":[[8,\"layout/cluster\",[],[[\"@verticalAlign\",\"@noWrap\",\"@fullWidthOnMobile\"],[\"bottom\",true,true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"hotel-list-item/tags\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[\"@float\"],[\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"hotel-list-item/google-rating\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/hotel-list-item/details.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});