define("reiserei-app/models/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Location = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Location = function Location(_ref) {
    var lat = _ref.lat,
        lng = _ref.lng,
        address = _ref.address;

    _classCallCheck(this, Location);

    _defineProperty(this, "lat", void 0);

    _defineProperty(this, "lng", void 0);

    _defineProperty(this, "address", void 0);

    this.lat = lat;
    this.lng = lng;
    this.address = address;
  };

  _exports.Location = Location;
});