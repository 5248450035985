define("reiserei-app/enums/price-range", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PriceRange = _exports.PRICE_RANGE_HIGH = _exports.PRICE_RANGE_MEDIUM = _exports.PRICE_RANGE_CHEAP = _exports.PRICE_RANGE_BARGAIN = void 0;
  var PRICE_RANGE_BARGAIN = 0;
  _exports.PRICE_RANGE_BARGAIN = PRICE_RANGE_BARGAIN;
  var PRICE_RANGE_CHEAP = 1;
  _exports.PRICE_RANGE_CHEAP = PRICE_RANGE_CHEAP;
  var PRICE_RANGE_MEDIUM = 2;
  _exports.PRICE_RANGE_MEDIUM = PRICE_RANGE_MEDIUM;
  var PRICE_RANGE_HIGH = 3;
  _exports.PRICE_RANGE_HIGH = PRICE_RANGE_HIGH;
  var PriceRange;
  _exports.PriceRange = PriceRange;

  (function (PriceRange) {
    PriceRange[PriceRange["Bargain"] = 0] = "Bargain";
    PriceRange[PriceRange["Cheap"] = 1] = "Cheap";
    PriceRange[PriceRange["Medium"] = 2] = "Medium";
    PriceRange[PriceRange["High"] = 3] = "High";
  })(PriceRange || (_exports.PriceRange = PriceRange = {}));
});