define("reiserei-app/enums/ski-large-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SkiLargeDistance = void 0;
  var SkiLargeDistance;
  _exports.SkiLargeDistance = SkiLargeDistance;

  (function (SkiLargeDistance) {
    SkiLargeDistance[SkiLargeDistance["No"] = 0] = "No";
    SkiLargeDistance[SkiLargeDistance["Close"] = 1] = "Close";
    SkiLargeDistance[SkiLargeDistance["Direct"] = 2] = "Direct";
  })(SkiLargeDistance || (_exports.SkiLargeDistance = SkiLargeDistance = {}));
});