define("reiserei-app/enums/public-transport", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.PublicTransport = void 0;
  var PublicTransport;
  _exports.PublicTransport = PublicTransport;

  (function (PublicTransport) {
    PublicTransport[PublicTransport["Bad"] = 0] = "Bad";
    PublicTransport[PublicTransport["OK"] = 1] = "OK";
    PublicTransport[PublicTransport["Good"] = 2] = "Good";
  })(PublicTransport || (_exports.PublicTransport = PublicTransport = {}));
});