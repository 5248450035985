define("reiserei-app/components/hotel-list-item/location", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Cluster
    @gap='large'
    @gapVertical='small'
    @fullWidthOnMobile={{true}} as |Item|
  >
    <Item>
      <Layout::Cluster @gap='small' as |InnerItem|>
        <InnerItem>
          <Ui::Icon @icon='map-pin' />
        </InnerItem>
  
        <InnerItem>
          {{@hotel.city.name}}
        </InnerItem>
      </Layout::Cluster>
    </Item>
  
    <Item>
      <Layout::Cluster @gap='small' as |InnerItem|>
        <InnerItem>
          <img
            src={{state-image @hotel.city.state}}
            alt=''
            role='presentation'
            class='hotel-list-item-state-image'
          />
        </InnerItem>
  
        <InnerItem>
          {{@hotel.city.state.name}} > {{@hotel.city.region.name}}
        </InnerItem>
      </Layout::Cluster>
    </Item>
  </Layout::Cluster>
  */
  {
    "id": "RbLj9sB7",
    "block": "{\"symbols\":[\"Item\",\"InnerItem\",\"InnerItem\",\"@hotel\"],\"statements\":[[8,\"layout/cluster\",[],[[\"@gap\",\"@gapVertical\",\"@fullWidthOnMobile\"],[\"large\",\"small\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/cluster\",[],[[\"@gap\"],[\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ui/icon\",[],[[\"@icon\"],[\"map-pin\"]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,3],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,4,[\"city\",\"name\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[3]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"layout/cluster\",[],[[\"@gap\"],[\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"img\"],[15,\"src\",[30,[36,0],[[32,4,[\"city\",\"state\"]]],null]],[14,\"alt\",\"\"],[14,\"role\",\"presentation\"],[14,0,\"hotel-list-item-state-image\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\\n      \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[32,4,[\"city\",\"state\",\"name\"]]],[2,\" > \"],[1,[32,4,[\"city\",\"region\",\"name\"]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[2]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"state-image\"]}",
    "moduleName": "reiserei-app/components/hotel-list-item/location.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});