define("reiserei-app/components/marker-layer", ["exports", "ember-leaflet/components/marker-layer"], function (_exports, _markerLayer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _markerLayer.default;
    }
  });
});