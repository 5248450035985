define("reiserei-app/components/hotel-list-item/google-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='text-right'>
    <Layout::VerticalStack @gap='xsmall' as |StackItem|>
      <StackItem>
        <Layout::Cluster @gap='xsmall' @position='right' as |Item|>
          <Item>
            <Ui::Icon @icon='star' />
          </Item>
  
          <Item>
            <div class='text-bold line-height-1'>
              {{@hotel.googleRating}}
            </div>
          </Item>
        </Layout::Cluster>
      </StackItem>
  
      <StackItem>
        <div>
          <a
            href='{{@hotel.googlePlaceUrl}}'
            target='_blank'
            rel='noopener noreferrer'
            class='font-size-small-2 text-nowrap display-block'
          >
            {{@hotel.googleRatingCount}} Google reviews
          </a>
        </div>
      </StackItem>
    </Layout::VerticalStack>
  </div>
  */
  {
    "id": "OtLlZnyo",
    "block": "{\"symbols\":[\"StackItem\",\"Item\",\"@hotel\"],\"statements\":[[10,\"div\"],[14,0,\"text-right\"],[12],[2,\"\\n  \"],[8,\"layout/vertical-stack\",[],[[\"@gap\"],[\"xsmall\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"layout/cluster\",[],[[\"@gap\",\"@position\"],[\"xsmall\",\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"ui/icon\",[],[[\"@icon\"],[\"star\"]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,2],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[14,0,\"text-bold line-height-1\"],[12],[2,\"\\n            \"],[1,[32,3,[\"googleRating\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[2]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"a\"],[15,6,[31,[[32,3,[\"googlePlaceUrl\"]]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener noreferrer\"],[14,0,\"font-size-small-2 text-nowrap display-block\"],[12],[2,\"\\n          \"],[1,[32,3,[\"googleRatingCount\"]]],[2,\" Google reviews\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/hotel-list-item/google-rating.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});