define("reiserei-app/components/site-wrapper/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='site-wrapper'>
    <SiteWrapper::Header />
  
    <SiteWrapper::Main>
      {{yield}}
    </SiteWrapper::Main>
  </div>
  */
  {
    "id": "K4LEDOSW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"site-wrapper\"],[12],[2,\"\\n  \"],[8,\"site-wrapper/header\",[],[[],[]],null],[2,\"\\n\\n  \"],[8,\"site-wrapper/main\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[18,1,null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/site-wrapper/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});