define("reiserei-app/enums/ski-small-distance", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.SkiSmallDistance = void 0;
  var SkiSmallDistance;
  _exports.SkiSmallDistance = SkiSmallDistance;

  (function (SkiSmallDistance) {
    SkiSmallDistance[SkiSmallDistance["No"] = 0] = "No";
    SkiSmallDistance[SkiSmallDistance["Close"] = 1] = "Close";
    SkiSmallDistance[SkiSmallDistance["Direct"] = 2] = "Direct";
  })(SkiSmallDistance || (_exports.SkiSmallDistance = SkiSmallDistance = {}));
});