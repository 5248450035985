define("reiserei-app/enums/food-availability", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.FoodAvailability = void 0;
  var FoodAvailability;
  _exports.FoodAvailability = FoodAvailability;

  (function (FoodAvailability) {
    FoodAvailability[FoodAvailability["None"] = 0] = "None";
    FoodAvailability[FoodAvailability["Breakfast"] = 1] = "Breakfast";
    FoodAvailability[FoodAvailability["HalfBoard"] = 2] = "HalfBoard";
    FoodAvailability[FoodAvailability["AllInclusive"] = 3] = "AllInclusive";
  })(FoodAvailability || (_exports.FoodAvailability = FoodAvailability = {}));
});