define("reiserei-app/components/ui/button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    {{! template-lint-disable require-button-type }}
  <button class='button' ...attributes>
    {{yield}}
  </button>
  */
  {
    "id": "tbb5lt9E",
    "block": "{\"symbols\":[\"&attrs\",\"&default\"],\"statements\":[[11,\"button\"],[24,0,\"button\"],[17,1],[12],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/ui/button.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});