define("reiserei-app/components/site-wrapper/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='site-header'>
    <SiteWrapper::HeaderBar />
  </div>
  */
  {
    "id": "S0pdCNCQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"site-header\"],[12],[2,\"\\n  \"],[8,\"site-wrapper/header-bar\",[],[[],[]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/site-wrapper/header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});