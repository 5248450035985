define("reiserei-app/components/hotel-list-item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Ui::Card>
    <div class='hotel-list-item'>
      <div class='hotel-list-item__image-wrapper'>
        <HotelListItem::Image @image={{@hotel.image}} />
      </div>
  
      <div class='hotel-list-item__content'>
        <Layout::VerticalStack @gap='small' as |StackItem|>
          <StackItem>
            <HotelListItem::Header @hotel={{@hotel}} />
          </StackItem>
  
          <StackItem>
            <div class='padding-bottom-3'>
              <HotelListItem::Location @hotel={{@hotel}} />
            </div>
          </StackItem>
  
          <StackItem class='margin-top-auto'>
            <HotelListItem::Tagline @hotel={{@hotel}} />
          </StackItem>
  
          <StackItem>
            <HotelListItem::Details @hotel={{@hotel}} />
          </StackItem>
        </Layout::VerticalStack>
      </div>
    </div>
  </Ui::Card>
  */
  {
    "id": "M81lcVDT",
    "block": "{\"symbols\":[\"StackItem\",\"@hotel\"],\"statements\":[[8,\"ui/card\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"hotel-list-item\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"hotel-list-item__image-wrapper\"],[12],[2,\"\\n      \"],[8,\"hotel-list-item/image\",[],[[\"@image\"],[[32,2,[\"image\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"hotel-list-item__content\"],[12],[2,\"\\n      \"],[8,\"layout/vertical-stack\",[],[[\"@gap\"],[\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"hotel-list-item/header\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"div\"],[14,0,\"padding-bottom-3\"],[12],[2,\"\\n            \"],[8,\"hotel-list-item/location\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n          \"],[13],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[[24,0,\"margin-top-auto\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"hotel-list-item/tagline\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n\\n        \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[8,\"hotel-list-item/details\",[],[[\"@hotel\"],[[32,2]]],null],[2,\"\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/hotel-list-item/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});