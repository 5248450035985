define("reiserei-app/utils/api-where", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.whereStringContains = whereStringContains;
  _exports.whereMinMax = whereMinMax;

  function whereStringContains(variables, fieldName, value) {
    variables["".concat(fieldName, "_contains")] = value;
  }

  function whereMinMax(variables, fieldName, value) {
    if (typeof value.min === 'number') {
      variables["".concat(fieldName, "_gte")] = value.min;
    }

    if (typeof value.max === 'number') {
      variables["".concat(fieldName, "_lte")] = value.max;
    }
  }
});