define("reiserei-app/utils/get-api-host", ["exports", "reiserei-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getApiHost = getApiHost;

  function getApiHost() {
    return _environment.default.apiHost;
  }
});