define("reiserei-app/components/hotel-list-item/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Cluster @gapVertical='small' as |Item|>
    <Item>
      <h2 class='hotel-list-item__title'>
        {{@hotel.name}}
      </h2>
    </Item>
  
    <Item>
      <Ui::Stars @stars={{@hotel.hotelCategory}} />
    </Item>
  
    <Item @float='right'>
      <div class='hotel-list-item__price-range'>
        {{enum/price-range @hotel.priceRange}}
      </div>
    </Item>
  </Layout::Cluster>
  */
  {
    "id": "ZsniVvrT",
    "block": "{\"symbols\":[\"Item\",\"@hotel\"],\"statements\":[[8,\"layout/cluster\",[],[[\"@gapVertical\"],[\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"h2\"],[14,0,\"hotel-list-item__title\"],[12],[2,\"\\n      \"],[1,[32,2,[\"name\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"ui/stars\",[],[[\"@stars\"],[[32,2,[\"hotelCategory\"]]]],null],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\\n  \"],[8,[32,1],[],[[\"@float\"],[\"right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"hotel-list-item__price-range\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,2,[\"priceRange\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"enum/price-range\"]}",
    "moduleName": "reiserei-app/components/hotel-list-item/header.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});