define("reiserei-app/components/hotel-search/filters/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    Filter
  */
  {
    "id": "qbxWdTcP",
    "block": "{\"symbols\":[],\"statements\":[[2,\"Filter\"]],\"hasEval\":false,\"upvars\":[]}",
    "moduleName": "reiserei-app/components/hotel-search/filters/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});