define("reiserei-app/models/image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.Image = void 0;

  function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var Image = // 500px
  // 750px
  // 1000px
  function Image(options) {
    var _options$formats, _options$formats$smal, _options$formats2, _options$formats2$med, _options$formats3, _options$formats3$lar;

    _classCallCheck(this, Image);

    _defineProperty(this, "fullUrl", void 0);

    _defineProperty(this, "smallUrl", void 0);

    _defineProperty(this, "mediumUrl", void 0);

    _defineProperty(this, "largeUrl", void 0);

    this.fullUrl = options.url;
    this.smallUrl = (_options$formats = options.formats) === null || _options$formats === void 0 ? void 0 : (_options$formats$smal = _options$formats.small) === null || _options$formats$smal === void 0 ? void 0 : _options$formats$smal.url;
    this.mediumUrl = (_options$formats2 = options.formats) === null || _options$formats2 === void 0 ? void 0 : (_options$formats2$med = _options$formats2.medium) === null || _options$formats2$med === void 0 ? void 0 : _options$formats2$med.url;
    this.largeUrl = (_options$formats3 = options.formats) === null || _options$formats3 === void 0 ? void 0 : (_options$formats3$lar = _options$formats3.large) === null || _options$formats3$lar === void 0 ? void 0 : _options$formats3$lar.url;
  };

  _exports.Image = Image;
});