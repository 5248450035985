define("reiserei-app/components/hotel-list-item/tagline", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='text-bold'>
    {{#if (eq @hotel.waterDistance 2)}}
      Hotel direkt am Wasser
    {{/if}}
  
    {{#if (eq @hotel.mountainDistance 2)}}
      Hotel direkt am Berg
    {{/if}}
  
    {{#if (eq @hotel.skiSmallDistance 2)}}
      Hotel direkt an kleinem Skigebiet
    {{/if}}
  
    {{#if (eq @hotel.skiLargeDistance 2)}}
      Hotel direkt an grossem Skigebiet
    {{/if}}
  </div>
  */
  {
    "id": "97WHNw9V",
    "block": "{\"symbols\":[\"@hotel\"],\"statements\":[[10,\"div\"],[14,0,\"text-bold\"],[12],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"waterDistance\"]],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    Hotel direkt am Wasser\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"mountainDistance\"]],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    Hotel direkt am Berg\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"skiSmallDistance\"]],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    Hotel direkt an kleinem Skigebiet\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"skiLargeDistance\"]],2],null]],null,[[\"default\"],[{\"statements\":[[2,\"    Hotel direkt an grossem Skigebiet\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\"]}",
    "moduleName": "reiserei-app/components/hotel-list-item/tagline.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});