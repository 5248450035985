define("reiserei-app/components/site-wrapper/header-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='header-bar'>
    <LinkTo @route='index' title='rumreiserei' class='header-bar-logo-link'>
      {{svg-jar 'rumreiserei' class='header-bar-logo'}}
    </LinkTo>
  </div>
  */
  {
    "id": "12s9uanJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header-bar\"],[12],[2,\"\\n  \"],[8,\"link-to\",[[24,\"title\",\"rumreiserei\"],[24,0,\"header-bar-logo-link\"]],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"rumreiserei\"],[[\"class\"],[\"header-bar-logo\"]]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"svg-jar\"]}",
    "moduleName": "reiserei-app/components/site-wrapper/header-bar.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});