define("reiserei-app/components/ui/tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='tag'>
    <Layout::Cluster as |Item|>
      {{#if @icon}}
        <Item>
          <Ui::Icon @icon={{@icon}} />
        </Item>
      {{/if}}
  
      <Item>
        {{@label}}
      </Item>
    </Layout::Cluster>
  </div>
  */
  {
    "id": "F2g+gvN6",
    "block": "{\"symbols\":[\"Item\",\"@icon\",\"@label\"],\"statements\":[[10,\"div\"],[14,0,\"tag\"],[12],[2,\"\\n  \"],[8,\"layout/cluster\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,0],[[32,2]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ui/icon\",[],[[\"@icon\"],[[32,2]]],null],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[1,[32,3]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\"]}",
    "moduleName": "reiserei-app/components/ui/tag.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});