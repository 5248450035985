define("reiserei-app/components/hotel-list-item/tags", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <Layout::Cluster @gap='small' as |Item|>
    {{#each @hotel.tags as |tag|}}
      <Item>
        <Ui::Tag @label={{enum/hotel-tag tag}} />
      </Item>
    {{/each}}
  </Layout::Cluster>
  */
  {
    "id": "hQed20dK",
    "block": "{\"symbols\":[\"Item\",\"tag\",\"@hotel\"],\"statements\":[[8,\"layout/cluster\",[],[[\"@gap\"],[\"small\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,3,[\"tags\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,1],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"ui/tag\",[],[[\"@label\"],[[30,[36,0],[[32,2]],null]]],null],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[2]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"enum/hotel-tag\",\"-track-array\",\"each\"]}",
    "moduleName": "reiserei-app/components/hotel-list-item/tags.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});