define("reiserei-app/components/layout/sidebar-left/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class='sidebar-left'>
    {{yield
      (hash
        Sidebar=(component 'layout/sidebar-left/-sidebar')
        Main=(component 'layout/sidebar-left/-main')
      )
    }}
  </div>
  */
  {
    "id": "FDMmYqtQ",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"sidebar-left\"],[12],[2,\"\\n  \"],[18,1,[[30,[36,1],null,[[\"Sidebar\",\"Main\"],[[30,[36,0],[\"layout/sidebar-left/-sidebar\"],null],[30,[36,0],[\"layout/sidebar-left/-main\"],null]]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"component\",\"hash\"]}",
    "moduleName": "reiserei-app/components/layout/sidebar-left/index.hbs"
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});